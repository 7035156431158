<script>
export default {
  props: ["order_details", "vatValue"],
  methods: {
    printReport() {
      window.print();
    },
  },
};
</script>

<template>
  <div
    class="modal fade"
    id="details"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("popups.sale_order_details") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="margin: 0"
          ></button>
        </div>

        <div class="modal-body">
          <table
            class="table table-striped table-centered table-nowrap align-middle"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <th scope="col">
                  {{ $t("purchases.item_name") }}
                </th>

                <th scope="col">
                  {{ $t("purchases.number") }}
                </th>
                <th scope="col">
                  {{ $t("purchases.gross_weight") }}
                </th>

                <th scope="col">
                  {{ $t("purchases.unit_price_ex_vat") }}
                </th>
                <th scope="col">
                  {{ $t("purchases.vat") }}
                </th>
                <th scope="col">
                  {{ $t("purchases.unit_price_vat") }}
                </th>
                <th scope="col">
                  {{ $t("purchases.sub_total") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(app, index) in order_details?.items"
                :key="app"
                class=""
              >
                <td>{{ index + 1 }}</td>
                <!-- {{ app }} -->
                <td>

                  {{$i18n.locale == 'ar'? app?.product?.name : app?.product?.name_en }}
                </td>

                <td>
                  {{ (app?.number? parseFloat(app?.number) : 0).toLocaleString() }}
                </td>

                <td>
                  {{ app?.qty }}
                </td>

                <td>
                  {{ parseFloat(app?.product?.sale_price)?.toLocaleString() }}
                </td>
                <td>
                  {{ app?.vat ? (vatValue * app?.product?.sale_price)?.toLocaleString() : "-" }}
                </td>
                <td>
                  {{ app?.vat
                    ? (
                        app?.product?.sale_price +
                        app?.product?.sale_price *
                        parseFloat(vatValue)
                    )?.toLocaleString()
                    : (app?.product?.sale_price)?.toLocaleString() }}
                </td>
                <td>
                  {{
                    app?.vat
                      ? (
                        (parseFloat(app?.qty) * parseFloat(app?.product?.sale_price)) +
                        parseFloat(app?.qty) *
                        parseFloat(app?.product?.sale_price) *
                            parseFloat(vatValue)
                        )?.toLocaleString()
                      : (parseFloat(app?.qty) * parseFloat(app?.product?.sale_price))?.toLocaleString()
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-end" colspan="7">
                  <b> {{ $t("purchases.total") }} </b>
                </td>
                <th class="total-style">
                  {{
                    (parseFloat(order_details?.untaxedTotal) + parseFloat(order_details?.taxTotal))?.toLocaleString()
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
          <button class="btn btn-success float-end" @click="printReport()">
            <i class="bx bx-printer"></i>
          </button>
          <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
            {{ $t("popups.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@media print {
  body *{
    visibility:hidden;
  }
  #details,#details *{
    visibility: visible;
  }
  #details .modal-dialog{
    width:100%
  }
}
</style>
